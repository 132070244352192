<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'logo'

  const sizes = {
    xs: 'w-8 h-4',
    sm: 'w-16 h-5',
    md: 'w-[73px] h-16',
    lg: 'w-10 h-10',
    xl: 'w-[108px] h-[44px]',
  }

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 226 73"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path
    d="M0.714355 3.14906C0.714355 2.47873 0.89535 1.94916 1.26403 1.56707C1.63272 1.18498 2.06843 0.977181 2.58459 0.943665H48.4354C49.1728 0.943665 49.7224 1.15147 50.0911 1.56707C50.4598 1.98268 50.6408 2.53906 50.6408 3.2362V15.081C50.6408 15.624 50.4464 16.1401 50.0643 16.6362C49.6822 17.1322 49.1191 17.3735 48.3885 17.3735H19.2558V32.2281H40.7668C41.3701 32.2281 41.8862 32.3957 42.3018 32.7309C42.7174 33.066 42.9252 33.6023 42.9252 34.3397V45.789C42.9252 46.3319 42.7375 46.8548 42.3756 47.3441C42.0069 47.8402 41.5041 48.0882 40.8673 48.0882H19.2558V70.0349C19.2558 71.4091 18.5184 72.0928 17.0504 72.0928H2.43712C1.28414 71.9319 0.714355 71.2616 0.714355 70.0818V3.14906Z"
    fill={fillColor}
  />
  <path
    d="M60.7495 3.0485C60.7495 2.47201 60.9238 1.97597 61.2791 1.56036C61.6276 1.14475 62.1572 0.936951 62.8611 0.936951H76.9448C77.4207 0.936951 77.9368 1.13135 78.4798 1.51344C79.0228 1.89553 79.2976 2.41168 79.2976 3.0485V69.9813C79.2976 71.389 78.5133 72.0928 76.9515 72.0928H62.8678C61.4601 72.0928 60.7629 71.389 60.7629 69.9813V3.0485H60.7495Z"
    fill={fillColor}
  />
  <path
    d="M87.7705 70.8527C88.0252 70.3701 88.2866 69.8874 88.5614 69.3914C88.8296 68.8953 89.1446 68.3926 89.4932 67.8831L111.051 35.687C107.378 30.1635 103.745 24.7003 100.152 19.2974C96.5585 13.9012 92.9253 8.43798 89.2519 2.90773C89.0575 2.653 88.91 2.41168 88.7961 2.19047C88.6821 1.96926 88.6084 1.72794 88.5816 1.47321C88.6419 1.21849 88.8296 1.09783 89.1312 1.11123C89.4329 1.12464 89.7479 1.05761 90.063 0.896729H106.734C107.914 0.896729 108.839 1.50673 109.516 2.72003L121.254 20.3498L133.139 2.72003C133.809 1.50673 134.734 0.896729 135.92 0.896729H151.874C152.223 0.896729 152.551 0.903431 152.853 0.923541C153.155 0.943651 153.416 0.997278 153.644 1.09112C153.865 1.18497 153.979 1.33245 153.979 1.52014C153.979 1.90223 153.805 2.31783 153.45 2.76696L131.409 35.687L153.879 69.2238C154.2 69.6729 154.448 70.0818 154.623 70.4438C154.797 70.8125 154.871 71.1208 154.837 71.3756C154.837 71.8515 154.549 72.0928 153.973 72.0928H136.819C136.276 72.0928 135.78 71.9319 135.331 71.6102C134.881 71.2951 134.533 70.8594 134.278 70.3164L121.247 51.0108L108.356 70.3164C108.102 70.8594 107.746 71.2884 107.304 71.6102C106.855 71.9319 106.359 72.0928 105.816 72.0928H88.6151C88.3603 72.0928 88.1324 72.0191 87.9447 71.8783C87.7503 71.7375 87.6565 71.5364 87.6565 71.2817C87.6565 71.1208 87.69 70.9801 87.7504 70.8527H87.7705Z"
    fill={fillColor}
  />
  <path
    d="M226 36.592C226 39.9772 225.571 43.1947 224.706 46.2448C223.842 49.2948 222.615 52.137 221.02 54.7714C219.424 57.4058 217.514 59.7989 215.295 61.9373C213.076 64.0756 210.616 65.8989 207.915 67.4005C205.213 68.902 202.304 70.0617 199.173 70.8728C196.043 71.6906 192.798 72.0928 189.447 72.0928H165.824C165.248 72.0928 164.738 71.9655 164.289 71.7107C163.84 71.456 163.619 70.913 163.619 70.0818V3.14905C163.619 2.47871 163.8 1.93575 164.169 1.52014C164.537 1.10453 165.087 0.896729 165.824 0.896729H189.447C192.798 0.896729 196.036 1.31233 199.147 2.14355C202.264 2.97476 205.166 4.15455 207.868 5.68961C210.569 7.22467 213.036 9.06139 215.268 11.1998C217.5 13.3381 219.411 15.7379 220.993 18.3857C222.575 21.0402 223.801 23.8959 224.68 26.9593C225.558 30.0294 225.993 33.2336 225.993 36.592H226ZM182.16 18.0975V54.9457H186.182C186.92 54.9457 187.637 54.9189 188.341 54.872C189.045 54.825 189.748 54.7714 190.452 54.7044C192.845 54.51 195.084 53.9335 197.162 52.9548C199.24 51.9828 201.05 50.7025 202.599 49.1205C204.147 47.5385 205.361 45.6817 206.239 43.5366C207.117 41.3983 207.553 39.0655 207.553 36.5383C207.553 33.857 207.05 31.3902 206.044 29.1379C205.039 26.8855 203.671 24.9349 201.949 23.2926C200.226 21.6502 198.201 20.3699 195.889 19.4583C193.569 18.5466 191.103 18.0908 188.488 18.0908H182.16V18.0975Z"
    fill={fillColor}
  />
</svg>
